// eslint-ignore-file
import React, { useEffect, useRef } from "react";

import { usePrivy, useWallets } from "@privy-io/react-auth";
import * as Sentry from "@sentry/react";

import useAccountInfo from "src/hooks/apis/useAccountInfo";
import useUpdateUser from "src/hooks/apis/useUpdateUser";

const PrivyWatcher = () => {
  const { user, createWallet } = usePrivy();
  const { wallets } = useWallets();
  const { trigger: updateUser } = useUpdateUser();
  const { data: accountInfo } = useAccountInfo();
  const walletCreationAttempted = useRef(false);

  const embeddedWallets = wallets.filter(
    wallet => wallet.walletClientType === "privy"
  );

  useEffect(() => {
    if (accountInfo && embeddedWallets.length === 0 && !walletCreationAttempted.current) {
      walletCreationAttempted.current = true;
      (async () => {
        try {
          await createWallet({ createAdditional: false });
          await updateUser({ privy_updated: true });
        }
        catch (error) {
          console.error("Error creating wallet", error);
        }
      })();
    }
  }, [accountInfo, embeddedWallets.length, createWallet, updateUser]);

  useEffect(() => {
    if (user?.linkedAccounts) {
      (async () => {
        try {
          await updateUser({ privy_updated: true });
        }
        catch (error) {
          console.error("Error updating user", error);
        }
      })();
    }
  }, [user?.linkedAccounts, updateUser]);

  return null;
};

const PrivyWatcherWithErrorBoundary = () => (
  <Sentry.ErrorBoundary
    fallback={null}
    onError={(error) => {
      console.error("PrivyWatcher error:", error);
    }}
  >
    <PrivyWatcher />
  </Sentry.ErrorBoundary>
);

export default PrivyWatcherWithErrorBoundary;

import React, { lazy, useMemo } from "react";

import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { SERIES_ROOT } from "src/constants";

import { AppContext } from "./context";
import DialogWrapper from "./dialog-wrapper";
import PrivyWatcher from "./privy-watcher";
import Routes from "./routes";
import WebhookEventHandler from "./webhook-event-handler";

const ReloadPage = lazy(() => import("src/pages/Error/ReloadPage"));

const getFlowContext = (pathname) => {
  const pathSegments = pathname.split("/");
  if (pathSegments.length < 3) {
    return [null, null]; // Not enough segments to determine context
  }
  if (pathSegments[1] && pathSegments[1] === "invitation") {
    return [null, null]; // Invitation context is handled separately
  }
  if (
    pathSegments.length >= 3
    && Object.values(SERIES_ROOT).includes("/" + pathSegments[1])
  ) {
    return [pathSegments[1], pathSegments[2]];
  }
  return [null, null]; // No valid context found
};

const Content = ({ hasError }) => {
  const location = useLocation();
  const [context, value] = getFlowContext(location.pathname);
  const contextValue = useMemo(() => ({ context, value }), [context, value]);
  if (hasError) {
    return <ReloadPage />;
  }

  return (
    <AppContext.Provider value={contextValue}>
      <Routes />
      <DialogWrapper />
      <PrivyWatcher />
      <WebhookEventHandler />
    </AppContext.Provider>
  );
};

Content.propTypes = {
  hasError: PropTypes.bool,
};

export default Content;

import React from "react";

const AppContext = React.createContext({ context: null, value: null });

export default function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContext.Provider");
  }
  return context;
}

export { AppContext };

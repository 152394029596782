export const ROUTE_PATH = {
  ROOT                 : "",
  US                   : "us",
  INTERNATIONAL        : "international",
  START                : "start",
  AUTH                 : "auth",
  AUTH_WITH_SERIES     : "auth-with-series",
  KYB_FLOW             : "kyb-flow",
  INVITE_DISABLED      : "invite-disabled",
  SERIES_NOT_PUBLISHED : "series-not-published",
  INVALID_INVITE       : "invalid-invite",
  ACCEPT_GRANT         : "accept",
  SYNDICATE            : "join",
  PUBLIC               : "public",
};

export const AUTH_LEVEL = {
  FREE      : "FREE",
  PUBLIC    : "PUBLIC",
  SIGN_IN   : "SIGN_IN",
  DASHBOARD : "DASHBOARD",
};

export const CUSTOMER_SUPPORT = {
  INTERCOM : "intercom",
  ERXES    : "erxes"
};

export const APP_STAGE = {
  DEV        : "dev",
  STAGE      : "stage",
  PRODUCTION : "production",
};

export const ORGANIZATION = {
  FAIRMINT : "fairmint",
  QUARTZ   : "quartz",
};

export const COMPANY_TYPE = {
  LLC    : "Limited Liability Company",
  LP     : "Limited Partnership",
  C_COPR : "C Corporation",
  S_COPR : "S Corporation",
  GP     : "General Partnership",
  FE     : "Foreign Entity",
  TRUST  : "Trust",
  FUND   : "Investment company or fund",
};

export const COMPANY_TRUST_TYPE = {
  REVOCABLE   : "Revocable",
  IRREVOCABLE : "Irrevocable",
};

export const COMPANY_FUND_TYPE = {
  INVESTMENT_COMPANY   : "An investment company",
  A_3_C_1_FUND         : "A 3(c)(1) fund",
  A_3_C_7_FUND         : "A 3(c)(7) fund",
  ANOTHER_TYPE_OF_FUND : "Another type of fund",
};

export const COMPANY_STATUS_ACTION = {
  ACCEPT : "accept",
  REJECT : "reject",
};

export const ACCREDITATION_ACTION = {
  ACCEPT : "accept",
  REJECT : "reject",
};

export const KYC_ACTION = {
  ACCEPT : "accept",
  REJECT : "reject",
  REVIEW : "review",
};

export const AML_ACTION = {
  ACCEPT : "accept",
  REJECT : "reject",
  SKIP   : "skip",
};

export const VERIFICATION_STATUS = {
  PROHIBITED              : "PROHIBITED",
  BLOCKED                 : "BLOCKED",
  SANCTIONED              : "SANCTIONED",
  ERROR                   : "ERROR",
  KYC_NOT_SUBMITTED       : "KYC_NOT_SUBMITTED",
  KYC_NEED                : "KYC_NEED",
  KYC_REJECTED            : "KYC_REJECTED",
  KYC_IN_REVIEW           : "KYC_IN_REVIEW",
  AML_NEED                : "AML_NEED",
  AML_REJECTED            : "AML_REJECTED",
  TAX_MISSING             : "TAX_MISSING",
  KYB_NEED                : "KYB_NEED",
  KYB_ID_NEED             : "KYB_ID_NEED",
  KYB_IN_REVIEW           : "KYB_IN_REVIEW",
  KYB_REJECTED            : "KYB_REJECTED",
  ACCREDITATION_NEED      : "ACCREDITATION_NEED",
  NON_ACCREDITED          : "NON_ACCREDITED",
  NON_ACCREDITED_BUT      : "NON_ACCREDITED_BUT",
  ROW_VERIFIED            : "ROW_VERIFIED",
  ACCREDITED_NOT_VERIFIED : "ACCREDITED_NOT_VERIFIED",
  ACCREDITED_IN_REVIEW    : "ACCREDITED_IN_REVIEW",
  ACCREDITED_VERIFIED     : "ACCREDITED_VERIFIED",
  ACCREDITED_REJECTED     : "ACCREDITED_REJECTED",
  VISITOR                 : "VISITOR",
};

export const WEBSOCKET_EVENT = {
  ACCOUNT_BLOCKED           : "account_blocked",
  ACCOUNT_UNBLOCKED         : "account_unblocked",
  ACCOUNT_UPDATED           : "account_updated",
  ADMIN_TOKEN_ISSUE_FAILED  : "admin_token_issue_failed",
  ADMIN_TOKEN_ISSUE_SUCCESS : "admin_token_issue_success",
  BENEFICIAL_OWNER_UPDATED  : "beneficial_owner_updated",
  FUNDS_RECEIVED            : "funds_received",
  RESET_COUNTRY             : "reset_country",
  RESET_KYC                 : "reset_kyc",
  TOKEN_ISSUED              : "token_issued",
  TRANSACTION_UPDATED       : "transaction_updated",
  USER_UPDATED              : "user_updated",
  GRANT_SIGNED              : "grant_signed",
  VESTING                   : "vesting",
  VESTED                    : "vested"
};

export const ACCREDITATION_STATUS = {
  NO      : "no",
  NO_BUT  : "no but", // for 506(b) exception for 35 non-accredited investors
  INCOME  : "income",
  ASSETS  : "assets",
  LICENSE : "license",
};

export const COMPANY_ACCREDITATION_STATUS = {
  NO       : "no",
  OWNERS   : "owners",
  ASSETS   : "assets",
  BUSINESS : "business",
  FUND     : "fund",
};

export const EXEMPTION = {
  PROHIBITED : "prohibited",
  REGS       : "regS",
  REGD506C   : "regD506C",
  REGD506B   : "regD506B",
};

export const INVESTMENT_STATUS = {
  SIGN_NEEDED        : "SIGN_NEEDED",
  SIGNED             : "SIGNED",
  FUNDS_SENT         : "FUNDS_SENT",
  AWAITING_SIGNATURE : "AWAITING_SIGNATURE",
  FUNDS_RECEIVED     : "FUNDS_RECEIVED",
  ADMIN_REFUSED      : "ADMIN_REFUSED",
  TOKENS_ISSUED      : "TOKENS_ISSUED",
  CANCELED           : "CANCELED",
  COMMITTED          : "COMMITTED",
};

export const DIALOG_NAME = {
  WALLET_SETTINGS      : "WALLET_SETTINGS",
  KYC_RESET            : "KYC_RESET",
  KYC_FLOW             : "KYC_FLOW",
  KYC_FINISHED         : "KYC_FINISHED",
  KYC_STATUS           : "KYC_STATUS",
  KYB_REVIEW           : "KYB_REVIEW",
  COUNTRY_RESET        : "COUNTRY_RESET",
  ACCOUNT_BLOCKED      : "ACCOUNT_BLOCKED",
  ACCOUNT_UNBLOCKED    : "ACCOUNT_UNBLOCKED",
  ACCESS_UNAVAILABLE   : "ACCESS_UNAVAILABLE",
  VPN_BLOCKED          : "VPN_BLOCKED",
  SERIES_PAUSED        : "SERIES_PAUSED",
  MAX_AMOUNT_REACHED   : "MAX_AMOUNT_REACHED",
  UNLINK_WALLET        : "UNLINK_WALLET",
  GRANT_ACCEPT_SUCCESS : "GRANT_ACCEPT_SUCCESS"
};

export const FUNDING_TYPE = {
  BANK_TRANSFER     : "bank_transfer",
  USDC_TRANSFER     : "usdc_transfer",
  SOLANA_TRANSFER   : "solana_transfer",
  ETHEREUM_TRANSFER : "ethereum_transfer",
};

export const TX_STATUS = {
  QUEUED    : "queued",
  PENDING   : "pending",
  CONFIRMED : "confirmed",
  STUCK     : "stuck",
  FAILED    : "failed",
  DROPPED   : "dropped",
};

export const FILING_STATUS = {
  FILED     : "filed",
  PENDING   : "pending",
  NOT_FILED : "not_filed",
};

export const SERIES_STATUS = {
  SETUP           : "setup",
  READY           : "ready",
  LIVE            : "live",
  PAUSED          : "paused",
  CLOSED          : "closed",
  DELETED         : "deleted",
  SOFT_COMMITMENT : "soft_commitment",
};

export const SERIES_TYPE = {
  FUNDRAISING : "fundraising",
  GRANT       : "grant",
  SHARES      : "shares",
  ROLLUP      : "rollup",
  WARRANT     : "warrant", // for shares warrants (not for warrants on tokens)
  TOKEN       : "token", // for token warrants and plain tokens
  SPV         : "spv",
};

export const SERIES_ROOT = {
  fundraising : "/series",
  grants      : "/grants",
  farcaster   : "/community",
  draper      : "/community",
  apechain    : "/community",
  base        : "/community",
  shares      : "/series",
  token       : "/token",
  spv         : "/spv", // NOT YET IMPLEMENTED
};

export const CONTEXT = {
  SYNDICATE  : "community",
  SERIES     : "series",
  GRANTS     : "grants",
  INVITATION : "invitation",
  TOKEN      : "token",
  ROLLUP     : "rollup",
};

export const CONTRACT_EXEC_TRIGGER = {
  FUNDS  : "funds",
  TOKENS : "tokens",
};

export const S3_ASSET_TYPE = {
  KYC                 : "kyc",
  ACCREDITATION_PROOF : "accreditation_proof",
  CUSTOM_LEGAL_DOC    : "custom_legal_doc",
  COMPANY_DOC         : "company_doc",
  CAP_TABLE           : "cap_table",
};

export const CONTRACT_SECURITIES_TYPE = {
  CONVERTIBLE : "convertible",
  SHARE       : "share",
  TOKEN       : "token",
};

export const COMPENSATION_TYPE = {
  OPTION     : "OPTION",
  RSU        : "RSU",
  OPTION_ISO : "OPTION_ISO",
  OPTION_NSO : "OPTION_NSO",
};

export const CONTRACT_SUBTYPE = {
  RSU             : "rsu",
  OPTIONS         : "options",
  GRANT           : "grant",
  ROLLUP          : "rollup",
  WARRANT         : "warrant",
  SOFT_COMMITMENT : "soft_commitment",
};

export const GRANT_STATUS = {
  PENDING_TAX       : "PENDING_TAX",
  PENDING_SIGNATURE : "PENDING_SIGNATURE",
  USER_DECLINED     : "USER_DECLINED",
  CLIFF_PERIOD      : "CLIFF_PERIOD",
  READY_TO_ISSUE    : "READY_TO_ISSUE",
  READY_TO_EXERCISE : "READY_TO_EXERCISE",
  ISSUED            : "ISSUED",
  CANCELED          : "CANCELED",
  // Grants for V2 customers status below.
  SIGN_NEEDED       : "SIGN_NEEDED",
  VESTING           : "VESTING",
  VESTED            : "VESTED",
  EXERCISED         : "EXERCISED",
};

export const SYNDICATE = {
  FARCASTER : "farcaster",
  DRAPER    : "draper",
  APECHAIN  : "apechain",
  BASE      : "base",
};

export const INVITATION_LINK = {
  DEFAULT: "Default"
};

export const NON_ACCREDITED_USERS_ALLOWED = {
  DEFAULT: 35
};
